// ItemDetails.js
import React from 'react';
import { FaTag, FaClock, FaMapMarkerAlt, FaLink } from 'react-icons/fa';

const ItemDetails = ({ listing }) => (
  <div className="bg-white shadow-md rounded-lg p-6">
    <h2 className="text-2xl font-semibold mb-4">Item Details</h2>
    <div className="space-y-2">
      <p className="flex items-center"><FaTag className="mr-2 text-gray-500" /> Category: {listing.category}</p>
      <p className="flex items-center"><FaMapMarkerAlt className="mr-2 text-gray-500" /> Location: {listing.location}</p>
      {listing.productAge && <p className="flex items-center"><FaClock className="mr-2 text-gray-500" /> Age: {listing.productAge}</p>}
      {listing.product_link && (
        <a href={listing.product_link} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 hover:underline mb-2">
          <FaLink className="mr-2 text-gray-500" /> Original Product Link
        </a>
      )}
    </div>
  </div>
);

export default ItemDetails;
