import React, { useState } from 'react';
import BidModal from '../Browse/BidModal';

const ListingInfo = ({ listing, currentBid, onBidSubmit, bidMessage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBid = () => {
    setIsModalOpen(true);
  };

  const handleBidSubmit = async (amount, name, phoneNumber) => {
    const success = await onBidSubmit(amount, name, phoneNumber);
    if (success) {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h1 className="text-3xl font-bold mb-4">{listing.name}</h1>
      <p className="text-gray-600 mb-4">{listing.description}</p>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-sm text-gray-500">Original Price</p>
          <p className="text-xl font-semibold">₹{listing.original_price}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Current Bid</p>
          <p className="text-2xl font-bold text-blue-600">₹{currentBid}</p>
        </div>
      </div>
      <button 
        onClick={handleBid} 
        className="w-full bg-blue-600 text-white font-bold py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
      >
        Place Bid
      </button>
      {bidMessage && <p className="mt-2 text-sm text-green-600">{bidMessage}</p>}
      <BidModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleBidSubmit}
        currentBid={currentBid}
      />
    </div>
  );
};

export default ListingInfo;