import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from '../components/Layout/Layout';
import { CameraIcon, XIcon } from '@heroicons/react/outline';
import ListingCard from '../components/Home/ListingCard';
import { UserContext } from '../context/UserContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Google Analytics event tracking function
const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn("Google Analytics not initialized.");
  }
};

const InputField = ({ label, type, name, value, onChange, placeholder }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={(e) => {
        onChange(e);
        trackEvent('Form Input Changed', { field: name, value: e.target.value });
      }}
      placeholder={placeholder}
      className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
      required
    />
  </div>
);

const ImageUpload = ({ previewImages, handleImageChange, removeImage }) => (
  <div className="mb-8">
    <label className="block text-lg font-semibold text-gray-700 mb-2">
      Product Images
    </label>
    <div className="mt-2 p-6 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50">
      {previewImages.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {previewImages.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image}
                alt={`Preview ${index + 1}`}
                className="w-full h-32 object-cover rounded-md shadow-sm"
              />
              <button
                type="button"
                onClick={() => {
                  removeImage(index);
                  trackEvent('Image Removed', { imageIndex: index });
                }}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <XIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-6">
          <CameraIcon className="mx-auto h-16 w-16 text-gray-400 mb-4" />
          <p className="text-gray-500">No images uploaded yet</p>
        </div>
      )}
      <div className="mt-6 flex justify-center">
        <label
          htmlFor="image-upload"
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer transition duration-150 ease-in-out"
        >
          <span>{previewImages.length > 0 ? 'Add more images' : 'Upload images'}</span>
          <input
            id="image-upload"
            name="image"
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => {
              handleImageChange(e);
              const fileNames = Array.from(e.target.files).map(file => file.name);
              trackEvent('Image Uploaded', { fileNames });
            }}
            className="sr-only"
          />
        </label>
      </div>
    </div>
  </div>
);

const NavigationButtons = ({ handleBack, handleNext, isSubmitting, step }) => (
  <div className="flex justify-between">
    {step > 1 && (
      <button
        type="button"
        onClick={() => {
          handleBack();
          trackEvent('Navigation Click', { direction: 'Back', step });
        }}
        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700"
      >
        Back
      </button>
    )}
    {step < 4 ? (
      <button
        type="button"
        onClick={() => {
          handleNext();
          trackEvent('Navigation Click', { direction: 'Next', step });
        }}
        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
      >
        Next
      </button>
    ) : (
      <button
        type="submit"
        disabled={isSubmitting}
        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        {isSubmitting ? 'Submitting...' : 'List Item'}
      </button>
    )}
  </div>
);

function Sell() {
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);
  
  useEffect(() => {
    if (!isLoading && !user) {
      navigate('/login', { state: { from: '/sell' } });
    }
  }, [user, isLoading, navigate]);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    original_price: '',
    location: '',
    images: [],
    product_link: '',
    dateofpurchase: '',
  });

  const [previewImages, setPreviewImages] = useState([]);
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));

    setFormData((prevData) => ({ ...prevData, images: [...prevData.images, ...files] }));
    setPreviewImages((prevImages) => [...prevImages, ...newImages]);
  };

  const removeImage = (index) => {
    setFormData((prevData) => {
      const updatedImages = [...prevData.images];
      updatedImages.splice(index, 1);
      return { ...prevData, images: updatedImages };
    });
    setPreviewImages((prevImages) => {
      const updatedPreviews = [...prevImages];
      updatedPreviews.splice(index, 1);
      return updatedPreviews;
    });
  };

  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handleBack = () => setStep((prevStep) => prevStep - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
  
    const data = new FormData();
  
    if (user) {
      data.append('seller_id', user._id);
    }
  
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'images') {
        value.forEach((image) => data.append('images', image));
      } else {
        data.append(key, value);
      }
    });

    const token = localStorage.getItem('jwtToken');
    try {
      await axios.post(`${apiBaseUrl}/create-listing`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      trackEvent('Listing Submitted', { formData });
      navigate('/my-listings');
    } catch (error) {
      console.error('Error submitting form:', error);
      trackEvent('Listing Submission Error', { error: error.message });
      setError('Failed to submit the listing. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const TextAreaField = ({ label, name, value, onChange, placeholder }) => (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
          trackEvent('Form Input Changed', { field: name, value: e.target.value });
        }}
        placeholder={placeholder}
        className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
        required
        rows="4"
      />
    </div>
  );
  
  
  return (
    <Layout>
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Sell Your Item</h1>

        {/* Preview Section */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
          <ListingCard
            title={formData.name}
            description={formData.description}
            price={formData.price}
            daysLeft={30}
            imageUrl={previewImages[0]}
          />
        </div>

        {/* Form Section */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Item Details</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            {step === 1 && (
              <>
                <ImageUpload
                  previewImages={previewImages}
                  handleImageChange={handleImageChange}
                  removeImage={removeImage}
                />
                {error && <p className="text-red-500">{error}</p>}
              </>
            )}

            {step === 2 && (
              <>
                <InputField
                  label="Item Name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter item name"
                />
                <TextAreaField
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter item description"
                />
                <InputField
                  label="Price"
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="Enter price"
                />
                <InputField
                  label="Original Price"
                  type="number"
                  name="original_price"
                  value={formData.original_price}
                  onChange={handleChange}
                  placeholder="Enter original price"
                />
                <InputField
                  label="Location"
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Enter location"
                />
                <InputField
                  label="Purchase Date"
                  type="date"
                  name="dateofpurchase"
                  value={formData.dateofpurchase}
                  onChange={handleChange}
                />
              </>
            )}

            {step === 3 && (
              <>
                <InputField
                  label="Original Product Link"
                  type="text"
                  name="product_link"
                  value={formData.product_link}
                  onChange={handleChange}
                  placeholder="Enter original product link"
                />
              </>
            )}

            <NavigationButtons
              handleBack={handleBack}
              handleNext={handleNext}
              isSubmitting={isSubmitting}
              step={step}
            />
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Sell;
