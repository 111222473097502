import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../components/Layout/Layout';
import ImageCarousel from '../components/Browse/ImageCarousel';
import { useApi } from '../hooks/useApi';
import ItemDetails from '../components/ItemListing/ItemDetails';
import ListingInfo from '../components/ItemListing/ListingInfo';
import SellerInfo from '../components/ItemListing/SellerInfo';
import BidsList from '../components/ItemListing/BidsList';
import ModernQnASection from '../components/ItemListing/ModernQnASection';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// Track event function
const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn("Google Analytics not initialized.");
  }
};

const ItemListingPage = () => {
  const { id } = useParams();
  const [listing, setListing] = useState(null);
  const [currentBid, setCurrentBid] = useState(0);
  const [bidMessage, setBidMessage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [qna, setQna] = useState([]);

  const { isLoading, error, data: fetchedListing } = useApi(`${API_BASE_URL}/listing/${id}`);
  const { data: fetchedQnA } = useApi(`${API_BASE_URL}/listing/${id}/qna`);

  useEffect(() => {
    if (fetchedListing) {
      setListing(fetchedListing);
      setCurrentBid(fetchedListing.price || 0);
      
      // Track page view event
      trackEvent('page_view', { page_path: window.location.pathname, listing_id: id });
    }
  }, [fetchedListing, id]);

  useEffect(() => {
    if (fetchedQnA) {
      setQna(fetchedQnA);
    }
  }, [fetchedQnA]);

  const handleBidSubmit = async (amount, name, phoneNumber) => {
    console.log('Submitting bid:', { amount, name, phoneNumber });
    const newBidAmount = parseFloat(amount);
    
    if (newBidAmount <= currentBid) {
      setBidMessage('Your bid must be higher than the current bid.');
      return false;
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/listings/${id}/bid`, {
        amount: newBidAmount,
        name,
        phoneNumber
      }, {
        headers: {
          'Content-Type': 'application/json',
          // Add any necessary authentication headers here
        }
      });
  
      console.log('Bid response:', response.data);  // Log the full response for debugging
  
      if (response.data.success) {
        setCurrentBid(newBidAmount);
        setBidMessage(`Your bid of ₹${newBidAmount} has been placed successfully!`);
        setIsBidModalOpen(false);  // Close the modal on successful bid
        
        // Track bid placed event
        trackEvent('bid_placed', {
          listing_id: id,
          amount: newBidAmount,
          name: name,
          phone_number: phoneNumber
        });
        
        return true;
      } else {
        setBidMessage(response.data.message || 'Failed to place bid. Please try again.');
        return false;
      }
    } catch (error) {
      console.error('Error placing bid:', error);
      
      // Check if the error response contains a message
      if (error.response && error.response.data && error.response.data.message) {
        setBidMessage(error.response.data.message);
      } else {
        setBidMessage('An error occurred while placing the bid. Please try again.');
      }
  
      // Check if the bid was actually placed despite the error
      if (error.response && error.response.status === 200 && error.response.data && error.response.data.success) {
        console.log('Bid placed successfully despite error');
        setCurrentBid(newBidAmount);
        setIsBidModalOpen(false);  // Close the modal on successful bid
        
        // Track bid placed event
        trackEvent('bid_placed', {
          listing_id: id,
          amount: newBidAmount,
          name: name,
          phone_number: phoneNumber
        });
        
        return true;
      }
  
      return false;
    }
  };

  const handleQuestionSubmit = async (question, askedBy) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/listing/${id}/question`, { question, askedBy });
      setQna([...qna, response.data]);
      
      // Track question submitted event
      trackEvent('question_submitted', {
        listing_id: id,
        question: question,
        asked_by: askedBy
      });
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const handleAnswerSubmit = async (questionId, answer, answeredBy) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/listing/${id}/question/${questionId}/answer`, { answer, answeredBy });
      const updatedQna = qna.map(q => 
        q._id === questionId 
          ? { ...q, answers: [...q.answers, response.data] }
          : q
      );
      setQna(updatedQna);
      
      // Track answer submitted event
      trackEvent('answer_submitted', {
        listing_id: id,
        question_id: questionId,
        answer: answer,
        answered_by: answeredBy
      });
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  if (isLoading) return <Layout><div className="flex items-center justify-center h-screen">Loading...</div></Layout>;
  if (error) return <Layout><div className="flex items-center justify-center h-screen text-red-500">{error}</div></Layout>;
  if (!listing) return <Layout><div className="flex items-center justify-center h-screen">No listing found.</div></Layout>;

  return (
    <Layout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <ImageCarousel
              images={listing?.images || []}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
            />
            <ItemDetails listing={listing} />
          </div>
          <div className="space-y-6">
            <ListingInfo 
              listing={listing} 
              currentBid={currentBid} 
              onBidSubmit={handleBidSubmit} 
              bidMessage={bidMessage} 
            />
            <SellerInfo seller={listing.seller} itemId={id} />
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <BidsList bids={listing.bids} itemId={id} />
          <ModernQnASection 
            qna={qna}
            onQuestionSubmit={handleQuestionSubmit}
            onAnswerSubmit={handleAnswerSubmit}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ItemListingPage;
