import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';  // Import remains unchanged
import axios from 'axios';

// Create an Axios instance with the base URL from environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000', // Default URL for local development
});

export const UserContext = React.createContext({ user: null, setUser: () => {} });

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserProfile = async (userId) => {
    try {
      const response = await api.get(`/get-user/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      throw error;
    }
  };

  const login = async (token) => {
    try {
      localStorage.setItem('jwtToken', token);
      const decoded = jwtDecode(token);
      
      const userProfile = await fetchUserProfile(decoded.user_id);
      setUser(userProfile);
    } catch (error) {
      console.error('Failed to login:', error);
      logout();
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('jwtToken');
  };

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          if (decoded.exp * 1000 < Date.now()) {
            throw new Error('Token expired');
          }
          const userProfile = await fetchUserProfile(decoded.user_id);
          setUser(userProfile);
        } catch (error) {
          console.error('Failed to initialize auth:', error);
          logout();
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  return (
    <UserContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </UserContext.Provider>
  );
};
