//used in customlisting

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import BidModal from './BidModal';
// import EditListingModal from './EditListingModal';
import ImageCarousel from './ImageCarousel';
import { FaComment, FaGavel, FaExternalLinkAlt, FaEdit } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

// Helper function to calculate age
const calculateAge = (dateOfPurchase) => {
  const today = new Date();
  const purchaseDate = new Date(dateOfPurchase);
  let years = today.getFullYear() - purchaseDate.getFullYear();
  let months = today.getMonth() - purchaseDate.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months };
};

const ListingCard = ({ listing, onBidPlaced, onListingUpdated }) => {
  const { user } = useContext(UserContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isBidModalOpen, setIsBidModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentBid, setCurrentBid] = useState(0);
  const [bidMessage, setBidMessage] = useState('');
  const [productAge, setProductAge] = useState({ years: 0, months: 0 });
  const [commentsCount, setCommentsCount] = useState(0);
  const [bidsCount, setBidsCount] = useState(0);


  useEffect(() => {
    if (listing) {
      if (listing.comments) {
        setCommentsCount(listing.comments.length);
      }
      if (listing.bids) {
        setBidsCount(listing.bids.length);
      }
      if (listing.price) {
        setCurrentBid(listing.price);
      }
      if (listing.dateofpurchase) {
        setProductAge(calculateAge(listing.dateofpurchase));
      }
    }
  }, [listing]);

  if (!listing) {
    return <div>Error: Listing data is missing</div>;
  }

  const handleBid = () => {
    setIsBidModalOpen(true);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };


  const handleBidSubmit = async (amount, name, phoneNumber) => {
    console.log('Submitting bid with Listing Card:', { amount, name, phoneNumber });
    const newBidAmount = parseFloat(amount);
  
    if (newBidAmount <= currentBid) {
      setBidMessage('Your bid must be higher than the current bid.');
      return;
    }
  
    if (typeof onBidPlaced !== 'function') {
      setBidMessage('Unable to place bid due to a system error. Please try again later.');
      return;
    }
  
    try {
      const success = await onBidPlaced(name, newBidAmount, phoneNumber);
      console.log("success = ", success);
      if (success) {
        try {
          setCurrentBid(newBidAmount);
          setIsBidModalOpen(false);  // Changed from setIsModalOpen to setIsBidModalOpen
          setBidMessage(`Your bid of ₹${newBidAmount} has been placed successfully!`);
        } catch (stateUpdateError) {
          console.error('Error updating state:', stateUpdateError);
          setBidMessage('Bid placed successfully, but there was an error updating the display. Please refresh the page.');
        }
      } else {
        setBidMessage('Failed to place bid. Please try again.');
      }
    } catch (error) {
      console.error('Error in bid placement:', error);
      setBidMessage('An error occurred while placing the bid. Please try again.');
    }
  };

  const handleEditSubmit = async (updatedListing) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/listings/${listing._id}`, updatedListing, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        onListingUpdated(response.data);
        setIsEditModalOpen(false);
      } else {
        console.error('Failed to update listing');
      }
    } catch (error) {
      console.error('Error updating listing:', error);
    }
  };

  const isOwner = user && user._id === listing.seller;


  return (
    <div className="border rounded p-4 shadow-md hover:shadow-lg transition-shadow duration-300">
      <ImageCarousel 
        images={listing.images || []} 
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
      />
      <h2 className="text-xl font-bold mb-2">
        <Link to={`/listing/${listing._id}`} className="text-blue-500 hover:text-blue-700">
          {listing.name || 'Unnamed Listing'}
        </Link>
      </h2>
      <p className="text-gray-600 mb-2 overflow-hidden" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, lineHeight: '1.5rem', height: '4.5rem' }}>
        {listing.description || 'No description available'}
      </p>

      <p className="text-lg font-semibold mb-2">Highest Bid: ₹{currentBid}</p>
      {listing.product_link && (
        <a 
          href={/^https?:\/\//.test(listing.product_link) ? listing.product_link : `https://${listing.product_link}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue-500 hover:underline block text-sm mb-2"
        >
          Original Product Link
        </a>
      )}

      <p className="text-sm text-gray-500 mb-2">Original Price: ₹{listing.original_price || 'N/A'}</p>
      <p className="text-sm text-gray-500 mb-2">Location: {listing.location || 'Unknown'}</p>
      <p className="text-sm text-gray-500 mb-2">
        Product Age: {productAge.years} years and {productAge.months} months
      </p>

      <div className="flex justify-between items-center mb-2">
        <Link to={`/listing/${listing._id}`} className="flex items-center">
          <FaComment className="text-gray-500 mr-1" />
          <span className="text-sm text-gray-500">{commentsCount} Comments</span>
        </Link>
        <Link to={`/listing/${listing._id}`} className="flex items-center">
          <FaGavel className="text-gray-500 mr-1" />
          <span className="text-sm text-gray-500">{bidsCount} Bids</span>
        </Link>
      </div>

      {bidMessage && (
        <p className="text-sm text-green-600 mb-2">{bidMessage}</p>
      )}
      
      <div className="flex space-x-2">
        {!isOwner && (
          <button
            onClick={handleBid}
            className="flex-1 bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Place Bid
          </button>
        )}
        {isOwner && (
          <button
            onClick={handleEdit}
            className="flex-1 bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition-colors duration-300"
          >
            <FaEdit className="inline-block mr-2" />
            Edit Listing
          </button>
        )}
      </div>

      <BidModal
        isOpen={isBidModalOpen}
        onClose={() => setIsBidModalOpen(false)}
        onSubmit={handleBidSubmit}
        currentBid={currentBid}
      />
      {/* <EditListingModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={handleEditSubmit}
        listing={listing}
      /> */}
    </div>
  );
};

export default ListingCard;