import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';

function CountdownTimer({ deadline }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [deadline]);

  const formatTime = (time) => time.toString().padStart(2, '0');

  return (
    <div className="bg-gradient-to-r from-red-600 to-orange-500 text-white p-6 rounded-xl shadow-2xl max-w-md mx-auto">
      <div className="flex items-center justify-center mb-4">
        <AlertTriangle className="w-6 h-6 mr-2 animate-pulse" />
        <h2 className="text-2xl font-bold">Auction Ending Soon!</h2>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-4">
        {Object.entries(timeLeft).map(([interval, time]) => (
          <div key={interval} className="flex flex-col items-center">
            <div className="bg-white text-red-600 rounded-lg p-3 mb-2 w-16 h-16 flex items-center justify-center">
              <span className="text-3xl font-bold">{formatTime(time)}</span>
            </div>
            <span className="text-sm uppercase">{interval}</span>
          </div>
        ))}
      </div>
      <p className="text-center text-sm animate-bounce">
        Don't miss out on amazing deals!
      </p>
    </div>
  );
}

export default CountdownTimer;