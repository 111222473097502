import React, { useContext } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { UserContext } from '../context/UserContext'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '10px',
    margin: 'auto',
};


const GoogleLoginButton = () => {
    const { login } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLoginSuccess = async (response) => {
        console.log('Google login response:', response);

        const token = response.credential;
        console.log('Extracted token:', token);

        try {
            const res = await fetch(`${API_BASE_URL}/google-login`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ token }),
            });

            console.log('Backend response status:', res.status);
            const data = await res.json();
            console.log('Backend response data:', data);

            if (res.ok) {
                // Use the login function from UserContext
                await login(data.token);
                console.log('Login successful, user state updated.');
                navigate('/'); // Navigate to home page or dashboard
            } else {
                console.error('Login failed:', data);
                // You might want to show an error message to the user here
            }
        } catch (error) {
            console.error('Error during login process:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <div style={containerStyle}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin 
                    onSuccess={handleLoginSuccess}
                    onError={() => console.log('Login Failed')}
                    useOneTap
                />
            </GoogleOAuthProvider>
        </div>
    );
};

export default GoogleLoginButton;