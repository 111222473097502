import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../components/Layout/Layout';
import SearchBar from '../components/Browse/SearchBar';
import SortSelect from '../components/Browse/SortSelect';
import ListingCard from '../components/Browse/ListingCard';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
function CustomListings() {
  const { customUrl } = useParams();
  const [listings, setListings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('price');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiBaseUrl}/api/listings/${customUrl}`);
        
        if (Array.isArray(response.data)) {
          setListings(response.data);
        } else if (response.data && typeof response.data === 'object') {
          // If the response is a single listing object, wrap it in an array
          setListings([response.data]);
        } else {
          throw new Error('Unexpected data structure received from API');
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch listings. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customUrl]);

  const filteredListings = listings.filter(listing =>
    listing.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedListings = [...filteredListings].sort((a, b) => {
    if (sortBy === 'price') return a.price - b.price;
    if (sortBy === 'name') return a.name.localeCompare(b.name);
    return 0;
  });

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Listings for {customUrl}
        </h1>
        <div className="mb-6 flex flex-col sm:flex-row gap-4">
          <div className="sm:w-2/3">
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </div>
          <div className="sm:w-1/3">
            <SortSelect sortBy={sortBy} setSortBy={setSortBy} />
          </div>
        </div>
        {isLoading ? (
          <p className="text-center">Loading listings...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedListings.map((listing) => (
              <ListingCard key={listing._id} listing={listing} />
            ))}
          </div>
        )}
        {!isLoading && !error && sortedListings.length === 0 && (
          <p className="text-center">No listings found for this custom URL.</p>
        )}
      </div>
    </Layout>
  );
}

export default CustomListings;