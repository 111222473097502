import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Modal from './Modal'; // Import your Modal component

const ImageCarousel = ({ images, currentImageIndex, setCurrentImageIndex }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="relative h-48 mb-4 rounded overflow-hidden">
        {images.length > 0 ? (
          <img 
            src={images[currentImageIndex]} 
            alt={`Image ${currentImageIndex + 1}`} 
            className="w-full h-full object-cover cursor-pointer transition-transform transform hover:scale-105"
            onClick={() => openModal(images[currentImageIndex])}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <span className="text-gray-500">No image available</span>
          </div>
        )}
        {images.length > 1 && (
          <>
            <button 
              onClick={prevImage} 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1 hover:bg-opacity-75 transition-colors duration-200"
            >
              <ChevronLeft size={24} className="text-gray-800" />
            </button>
            <button 
              onClick={nextImage} 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1 hover:bg-opacity-75 transition-colors duration-200"
            >
              <ChevronRight size={24} className="text-gray-800" />
            </button>
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-50 px-2 py-1 rounded-full text-xs">
              {currentImageIndex + 1} / {images.length}
            </div>
          </>
        )}
      </div>

      {isModalOpen && (
        <Modal onClose={closeModal}>
          <img 
            src={selectedImage} 
            alt="Selected" 
            className="w-full h-auto max-w-full max-h-screen object-contain"
          />
        </Modal>
      )}
    </div>
  );
};

export default ImageCarousel;
