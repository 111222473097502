import React from 'react';

const ListingCard = ({ title, description, price, daysLeft, imageUrl }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex">
    <div className="w-1/3">
      {imageUrl ? (
        <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
      ) : (
        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
          <span className="text-gray-400">No image</span>
        </div>
      )}
    </div>
    <div className="w-2/3 p-4">
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title || "Your Item Name"}</h3>
      <p className="text-gray-600 mb-4">{description || "Your item description will appear here"}</p>
      <div className="flex justify-between items-center">
        <span className="text-blue-600 font-bold text-lg">₹{price || "0"}</span>
        <span className="text-gray-500 text-sm">({daysLeft} days left)</span>
      </div>
    </div>
  </div>
  );
};

export default ListingCard;

