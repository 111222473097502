import React, { useState } from 'react';
import { FaQuestion, FaReply } from 'react-icons/fa';
import axios from 'axios';
import { formatDate } from '../../utils/dateUtils'; 

const QuestionForm = ({ onSubmit, questionerName, setQuestionerName, newQuestion, setNewQuestion }) => (
  <form onSubmit={onSubmit} className="mb-6">
    <input
      type="text"
      value={questionerName}
      onChange={(e) => setQuestionerName(e.target.value)}
      placeholder="Your Name"
      required
      className="border p-2 rounded mb-2 w-full"
    />
    <textarea
      value={newQuestion}
      onChange={(e) => setNewQuestion(e.target.value)}
      placeholder="Ask a question..."
      required
      className="border p-2 rounded w-full mb-2"
    />
    <button type="submit" className="bg-blue-500 text-white p-2 rounded">
      Submit Question
    </button>
  </form>
);

const Comment = ({ comment, onReply }) => {
  return (
    <div className="bg-gray-100 p-4 rounded mb-4">
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-500">
          By {comment.by}
        </p>
        <p className="text-sm text-gray-500">
          {formatDate(comment.date)}
        </p>
      </div>
      <p className="mt-2">{comment.text}</p>
      <button 
        onClick={() => onReply(comment._id)} 
        className="text-blue-500 mt-2 flex items-center"
      >
        <FaReply className="mr-1" /> Reply
      </button>
      {comment.answers && comment.answers.length > 0 ? (
        comment.answers.map((answer) => (
          <div key={answer._id} className="mt-4 ml-4 pl-4 border-l">
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-500">
                By {answer.answeredBy}
              </p>
              <p className="text-sm text-gray-500">
                {formatDate(answer.answeredDate)}
              </p>
            </div>
            <p className="mt-2">{answer.text || 'No answer content'}</p>
          </div>
        ))
      ) : (
        <p>No answers yet.</p>
      )}
    </div>
  );
};




const AnswerForm = ({ onSubmit, answererName, setAnswererName, newAnswer, setNewAnswer, onCancel }) => (
  <form onSubmit={onSubmit} className="mb-4">
    <input
      type="text"
      value={answererName}
      onChange={(e) => setAnswererName(e.target.value)}
      placeholder="Your Name"
      required
      className="border p-2 rounded mb-2 w-full"
    />
    <textarea
      value={newAnswer}
      onChange={(e) => setNewAnswer(e.target.value)}
      placeholder="Write an answer..."
      required
      className="border p-2 rounded w-full mb-2"
    />
    <div className="flex space-x-4">
      <button type="submit" className="bg-blue-500 text-white p-2 rounded">
        Submit Answer
      </button>
      <button type="button" onClick={onCancel} className="bg-gray-500 text-white p-2 rounded">
        Cancel
      </button>
    </div>
  </form>
);

const ModernQnASection = ({ qna, onQuestionSubmit, onAnswerSubmit }) => {
  const [replyingTo, setReplyingTo] = useState(null);
  const [newQuestion, setNewQuestion] = useState('');
  const [questionerName, setQuestionerName] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [answererName, setAnswererName] = useState('');

  // Define the onReply function
  const onReply = (commentId) => {
    setReplyingTo(commentId);
  };

  const onCancelReply = () => {
    setReplyingTo(null);
    setNewAnswer('');
    setAnswererName('');
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault(); // This prevents the form's default submit action
    try {
      await onQuestionSubmit(newQuestion, questionerName); // Assuming this is the right function
      setNewQuestion('');
      setQuestionerName('');
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const handleAnswerSubmit = async (questionId) => {
    try {
      await onAnswerSubmit(questionId, newAnswer, answererName); // Assuming this is the right function
      setNewAnswer('');
      setAnswererName('');
      onCancelReply();
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <FaQuestion className="mr-2 text-gray-500" /> Have a query? Ask Question here.
      </h2>
      <QuestionForm 
        onSubmit={handleQuestionSubmit} // Note this is passing the handleQuestionSubmit
        questionerName={questionerName}
        setQuestionerName={setQuestionerName}
        newQuestion={newQuestion}
        setNewQuestion={setNewQuestion}
      />
      <div className="space-y-6">
        {qna.length > 0 ? (
          qna.map((item) => (
            <div key={item._id}>
              <Comment 
                comment={{
                  _id: item._id,
                  text: item.question,
                  by: item.askedBy,
                  date: item.askedDate,
                  answers: item.answers
                }} 
                onReply={onReply} // Pass the onReply function to Comment
              />
              {replyingTo === item._id && (
                <AnswerForm
                  onSubmit={(e) => {
                    e.preventDefault(); // Ensure preventDefault is called
                    handleAnswerSubmit(item._id);
                  }}
                  answererName={answererName}
                  setAnswererName={setAnswererName}
                  newAnswer={newAnswer}
                  setNewAnswer={setNewAnswer}
                  onCancel={onCancelReply}
                />
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No questions yet. Be the first to ask!</p>
        )}
      </div>
    </div>
  );
};

export default ModernQnASection;
