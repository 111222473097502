import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, CubeIcon, CalendarIcon, CurrencyDollarIcon, TruckIcon } from '@heroicons/react/outline';
import Layout from '../components/Layout/Layout';
import FeatureCard from '../components/Home/FeatureCard';
import BidTicker from '../components/Home/BidTicker'; 

const features = [
  { Icon: CubeIcon, title: "List Your Items", description: "Moving out? Quickly add your items to sell using our easy listing tool." },
  { Icon: CalendarIcon, title: "Set Your Deadline", description: "Choose when your auction ends based on your move-out schedule." },
  { Icon: CurrencyDollarIcon, title: "Get Bids", description: "Share your auction link to attract bids and secure the best offers." },
  { Icon: TruckIcon, title: "Complete the Sale", description: "Arrange payment and pickup with the winning bidder for a smooth transaction." }
];

function Home() {

  // Function to handle tracking when the "List your products" button is clicked
  const handleListProductsClick = () => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Button',
        event_label: 'List Your Products Now',
        value: 1, // Optional value, can track if needed
      });
    }
  };

  // Optional: Track when a feature card is viewed
  const handleFeatureView = (featureTitle) => {
    if (window.gtag) {
      window.gtag('event', 'view', {
        event_category: 'Feature Card',
        event_label: featureTitle,
      });
    }
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="text-center mb-16">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="text-blue-600">Moving Out?</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Get the best price for products on your move out sale!
          </p>
        </header>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">How It Works</h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <div 
                key={index}
                onMouseEnter={() => handleFeatureView(feature.title)} // Trigger event when the feature is viewed/hovered
              >
                <FeatureCard {...feature} />
              </div>
            ))}
          </div>
        </section>

        <section className="bg-blue-600 rounded-lg shadow-xl p-8 mb-16 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Get the best price and Move out with ease!</h2>
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
            <Link
              to="/sell"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
              onClick={handleListProductsClick} // Track click when user clicks "List your products now"
            >
              List your products now
              <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </section>

        {/* Replace with BidTicker component */}
        <section className="mb-16">
          <BidTicker />
        </section>
      </div>
    </Layout>
  );
}

export default Home;
