import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FaGavel, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { formatDate } from '../../utils/dateUtils';
import { UserContext } from '../../context/UserContext';

const Bid = ({ bid, bidderDetail, itemDetails }) => {
  const whatsappMessage = encodeURIComponent(
    `Hi ${bidderDetail?.name || 'there'},\nI got your bid for the ${window.location.href}`
  );
  

  return (
    <div className="bg-gray-100 p-4 rounded mb-4">
      <div className="flex items-center justify-between mb-2">
        <p className="text-sm text-gray-500">
          By {bidderDetail ? bidderDetail.name : 'Anonymous'}
        </p>
        <p className="text-sm text-gray-500">
          {formatDate(bid.timeOfBid)}
        </p>
      </div>
      <p className="text-2xl font-semibold mb-2">₹{bid.amount.toLocaleString()}</p>
      {bidderDetail && bidderDetail.phoneNumber && (
        <div className="flex items-center space-x-4 mt-2">
          <a href={`tel:${bidderDetail.phoneNumber}`} className="flex items-center text-blue-600 hover:text-blue-800">
            <FaPhone className="mr-2" />
            <span>{bidderDetail.phoneNumber}</span>
          </a>
          <a 
            href={`https://wa.me/${bidderDetail.phoneNumber}?text=${whatsappMessage}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-green-600 hover:text-green-800"
          >
            <FaWhatsapp className="mr-2" />
            <span>WhatsApp</span>
          </a>
        </div>
      )}
    </div>
  );
};

const BidsList = ({ itemId, bids = [], itemDetails }) => {
  const [bidderDetails, setBidderDetails] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  const { user } = useContext(UserContext);

  const fetchBidderDetails = async () => {

    try {
      const response = await axios.post(`${API_BASE_URL}/sendBidderDetails/${itemId}`, 
        { user_id: user ? user._id : 'not_logged_in' },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          }
        }
      );

      if (response.status === 200 && response.data && response.data.bidderDetails) {
        setBidderDetails(response.data.bidderDetails);
      } else {
        console.error('Failed to fetch bidder details', response.data);
      }
    } catch (error) {
      console.error('Error fetching bidder details:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (itemId) {
      fetchBidderDetails();
    }
  }, [itemId]);

  const sortedBids = [...bids].sort((a, b) => b.amount - a.amount);

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <FaGavel className="mr-2 text-gray-500" /> Bids ({bids.length})
      </h2>
      <div className="space-y-6">
        {sortedBids.length > 0 ? (
          sortedBids.map((bid) => {
            const bidderDetail = bidderDetails.find(detail => detail.amount === bid.amount && formatDate(detail.timeOfBid) === formatDate(bid.timeOfBid));
            return (
              <Bid 
                key={`${bid.amount}-${bid.timeOfBid}`}
                bid={bid}
                bidderDetail={bidderDetail}
                itemDetails={itemDetails}
              />
            );
          })
        ) : (
          <p className="text-gray-500">No bids have been placed yet.</p>
        )}
      </div>
    </div>
  );
};

export default BidsList;