// src/components/Home/FeatureCard.js
import React from 'react';

const FeatureCard = ({ Icon, title, description }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center">
    <Icon className="h-12 w-12 text-blue-500 mb-4" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-500">{description}</p>
  </div>
);

export default FeatureCard;