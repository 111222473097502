import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'; // Import NavLink
import { UserContext } from '../../context/UserContext';
import { Menu, Loader } from 'lucide-react';
import logo from '../../assets/images/moveoutlogo.svg';

const Header = () => {
  const { user, logout, loading } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Google Analytics event tracking for menu toggle
    window.gtag && window.gtag('event', 'menu_toggle', {
      event_category: 'Navigation',
      event_label: isMenuOpen ? 'Close Menu' : 'Open Menu',
    });
  };

  useEffect(() => {
    // Close the menu when user state changes
    setIsMenuOpen(false);
  }, [user]);

  const profileImage = user?.profile_image || "/api/placeholder/32/32";

  const handleNavigationClick = (label) => {
    // Google Analytics event tracking for navigation
    window.gtag && window.gtag('event', 'navigation_click', {
      event_category: 'Navigation',
      event_label: label,
    });
  };

  return (
    <header className="bg-white shadow-md">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link
              to="/"
              className="flex-shrink-0 flex items-center"
              onClick={() => handleNavigationClick('Home')}
            >
              <img className="h-12 w-auto" src={logo} alt="Logo" />
            </Link>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActive ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`
                }
                onClick={() => handleNavigationClick('Home')}
              >
                Home
              </NavLink>
              <NavLink
                to="/sell"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                    isActive ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`
                }
                onClick={() => handleNavigationClick('Sell')}
              >
                Sell
              </NavLink>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {loading ? (
              <Loader className="animate-spin h-5 w-5 text-gray-500" />
            ) : !user ? (
              <>
                <Link
                  to="/login"
                  className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => handleNavigationClick('Login')}
                >
                  Log in
                </Link>
                <Link
                  to="/signup"
                  className="bg-blue-600 text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => handleNavigationClick('Signup')}
                >
                  Sign up
                </Link>
              </>
            ) : (
              <div className="relative">
                <button onClick={toggleMenu} className="flex items-center focus:outline-none">
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={profileImage}
                    alt={`${user.name}'s profile`}
                  />
                </button>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <div className="px-4 py-2 text-sm text-gray-700">
                      Hello, {user.name}
                    </div>
                    <Link
                      to="/my-listings"
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => {
                        handleNavigationClick('My Listings');
                        setIsMenuOpen(false);
                      }}
                    >
                      My Listings
                    </Link>
                    <button
                      onClick={() => {
                        logout();
                        setIsMenuOpen(false);
                        // Google Analytics event tracking for logout
                        window.gtag && window.gtag('event', 'logout', {
                          event_category: 'User',
                          event_label: 'Logout',
                        });
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Log out
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="sm:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isActive ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
                }`
              }
              onClick={() => handleNavigationClick('Home')}
            >
              Home
            </NavLink>
            <NavLink
              to="/sell"
              className={({ isActive }) =>
                `block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                  isActive ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
                }`
              }
              onClick={() => handleNavigationClick('Sell')}
            >
              Sell
            </NavLink>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            {loading ? (
              <div className="px-4 py-2 text-sm text-gray-500">
                <Loader className="animate-spin h-5 w-5 inline mr-2" />
                Loading...
              </div>
            ) : user ? (
              <>
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={profileImage}
                      alt={`${user.name}'s profile`}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  <NavLink
                    to="/my-listings"
                    className={({ isActive }) =>
                      `block w-full text-left px-4 py-2 text-base font-medium ${
                        isActive ? 'text-gray-800 bg-gray-100' : 'text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                      }`
                    }
                    onClick={() => {
                      handleNavigationClick('My Listings');
                      setIsMenuOpen(false);
                    }}
                  >
                    My Listings
                  </NavLink>
                  <button
                    onClick={() => {
                      logout();
                      setIsMenuOpen(false);
                      window.gtag && window.gtag('event', 'logout', {
                        event_category: 'User',
                        event_label: 'Logout',
                      });
                    }}
                    className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    Log out
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-3 space-y-1">
                <NavLink to="/login" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                  Log in
                </NavLink>
                <NavLink to="/signup" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                  Sign up
                </NavLink>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
