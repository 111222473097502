import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ChevronRight, MapPin, Clock } from 'lucide-react';

const BidTicker = () => {
  const [latestBids, setLatestBids] = useState([]);

  useEffect(() => {
    const fetchLatestBids = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/latest-bids`);
        const data = await response.json();
        const bids = Array.isArray(data) ? data : [];
        setLatestBids(bids);
      } catch (error) {
        console.error('Error fetching latest bids:', error);
        setLatestBids([]);
      }
    };
    fetchLatestBids();
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800">Latest Bids</h2>
      <div className="space-y-6">
        {latestBids.length > 0 ? (
          latestBids.map((bid, index) => (
            <div key={index} className="flex flex-col sm:flex-row items-start bg-gray-50 rounded-lg p-4 shadow-md transition-all duration-300 hover:shadow-lg hover:bg-gray-100">
              <div className="w-full sm:w-32 h-32 rounded-lg overflow-hidden mb-4 sm:mb-0 shadow-inner">
                <img
                  src={bid.product?.images?.[0]}
                  alt={bid.product?.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="sm:ml-6 flex-1">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-2">
                  <div>
                    <h3 className="text-xl font-bold text-gray-800 mb-1">{bid.product?.name}</h3>
                    <p className="text-gray-600 text-sm sm:text-base">
                      <span className="font-semibold">{bid.name}</span> placed a bid of <span className="font-semibold text-green-600">INR {bid.amount?.toLocaleString()}</span>
                    </p>
                  </div>
                  <div className="flex items-center text-gray-500 mt-2 sm:mt-0 text-sm">
                    <Clock size={16} className="mr-1" />
                    {formatDistanceToNow(new Date(bid.timeOfBid), { addSuffix: true })}
                  </div>
                </div>
                <div className="flex items-center text-gray-600 mt-2 text-sm">
                  <MapPin size={16} className="mr-1" />
                  {bid.product?.location}
                </div>
              
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center py-8">No bids available at the moment.</p>
        )}
      </div>
    </div>
  );
};

export default BidTicker;