import React, { useState } from 'react';

const BidModal = ({ isOpen, onClose, onSubmit, currentBid }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^(\+91|0)?[6-9]\d{9}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (value && !validatePhoneNumber(value)) {
      setError('Please enter a valid Indian phone number');
    } else {
      setError('');
    }
  };

  const handleBidChange = (e) => {
    setBidAmount(e.target.value);
  };

  const handleBidIncrement = () => {
    const currentValue = parseFloat(bidAmount) || currentBid;
    setBidAmount((currentValue + 100).toString());
  };

  const handleBidDecrement = () => {
    const currentValue = parseFloat(bidAmount) || currentBid;
    const newValue = Math.max(currentValue - 100, currentBid + 1);
    setBidAmount(newValue.toString());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseFloat(bidAmount) <= currentBid) {
      setError('Your bid must be higher than the current bid.');
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Please enter a valid Indian phone number');
      return;
    }
    console.log('Submitting bid with details:', { bidAmount, name, phoneNumber });
    onSubmit(parseFloat(bidAmount), name, phoneNumber);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Place Your Bid</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
            className="w-full p-2 border rounded mb-4"
            required
          />
          <input
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="Your Phone Number (e.g., 9876543210)"
            className="w-full p-2 border rounded mb-4"
            required
          />
          <div className="flex items-center mb-4">
            <input
              type="number"
              value={bidAmount}
              onChange={handleBidChange}
              placeholder={`Current bid: ₹${currentBid}`}
              className="w-full p-2 border rounded"
              min={currentBid + 1}
              required
            />
            <div className="flex flex-col ml-2">
              <button type="button" onClick={handleBidIncrement} className="bg-gray-200 px-2 rounded-t">▲</button>
              <button type="button" onClick={handleBidDecrement} className="bg-gray-200 px-2 rounded-b">▼</button>
            </div>
          </div>
          {error && <p className="text-red-500 mb-2">{error}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Submit Bid
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BidModal;