import { formatDistanceToNow, parse, isValid, parseISO } from 'date-fns';

const CUSTOM_DATE_FORMAT = "EEE, dd MMM yyyy HH:mm:ss 'GMT'"; // Format for 'Sat, 10 Aug 2024 18:02:09 GMT'

export const formatDate = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return 'Invalid date';
  try {
    // Try parsing as ISO date format
    let date = parseISO(dateStr);
    
    // If not valid, try parsing with custom format
    if (!isValid(date)) {
      date = parse(dateStr, CUSTOM_DATE_FORMAT, new Date());
    }

    if (!isValid(date)) {
      throw new Error('Invalid date');
    }

    // Format distance to now and remove "about" prefix
    return formatDistanceToNow(date, { addSuffix: true }).replace(/^about\s*/, '');
  } catch (error) {
    console.error('Error formatting date:', error, 'Date string:', dateStr);
    return 'Invalid date';
  }
};
