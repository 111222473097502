import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser } from 'react-icons/fa';

const SellerInfo = ({ itemId }) => {
  const [sellerInfo, setSellerInfo] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  // Function to fetch seller information
  const fetchSellerInfo = async () => {
    console.log('Fetching seller info for item ID:', itemId); // Debug: Log itemId

    try {
      const response = await axios.get(`${API_BASE_URL}/sendSellerDetails/${itemId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      if (response.status === 200) {
        console.log('Seller info response:', response.data); // Debug: Log API response
        setSellerInfo(response.data);
      } else {
        console.error('Failed to fetch seller information:', response.data);
      }
    } catch (error) {
      console.error('Error fetching seller information:', error.response ? error.response.data : error.message);
    }
  };

  // Fetch seller info when component mounts or itemId changes
  useEffect(() => {
    if (itemId) {
      fetchSellerInfo();
    } else {
      console.warn('No itemId provided'); // Debug: Warn if itemId is not provided
    }
  }, [itemId]);

  return (
    <div className="bg-white shadow-md rounded-lg p-4 sm:p-6">
      <h2 className="text-xl font-semibold mb-4 sm:text-2xl">Seller Information</h2>
      {sellerInfo ? (
        <div className="space-y-2">
          <p className="flex items-center text-sm sm:text-base">
            <FaUser className="mr-2 text-gray-500" /> <span className="font-semibold">{sellerInfo.name}</span>
          </p>
          <p className="text-sm sm:text-base text-gray-700">{sellerInfo.description}</p>
        </div>
      ) : (
        <p className="text-sm sm:text-base text-gray-500">Loading seller information...</p>
      )}
    </div>
  );
};

export default SellerInfo;
