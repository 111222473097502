import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import Browse from './pages/Browse';
import Sell from './pages/Sell';
import CustomListings from './pages/CustomListings';
import ItemListingPage from './pages/ItemListingPage';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Login from './pages/Login';
import Signup from './pages/Signup';
import TestPage from './pages/Test';
import MyListings from './pages/MyListings';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-WTPZPXXYKS', {
        page_path: location.pathname,
      });
    }
  }, [location]);
}

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Router>
        <PageTrackerWrapper /> {/* Wrapper for tracking */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/my-listings" element={<MyListings />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/:customUrl" element={<CustomListings />} />
          <Route path="/listing/:id" element={<ItemListingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/test" element={<TestPage />} />
        </Routes>
        <ToastContainer />
      </Router>
    </GoogleReCaptchaProvider>
  );
}

// Separate component for page tracking
function PageTrackerWrapper() {
  usePageTracking();
  return null; // This component exists only to trigger the hook
}

export default App;
