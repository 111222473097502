import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, UserGroupIcon, LightningBoltIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import Layout from '../components/Layout/Layout';

function About() {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="text-center mb-16">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            About <span className="text-blue-600">Moveoutauction</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Moveoutauction is dedicated to making your moving process seamless and profitable. We connect those looking to declutter their homes with buyers eager to find great deals on quality items.
          </p>
        </header>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Our Values</h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white rounded-lg shadow-xl p-8 text-center">
              <UserGroupIcon className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Building Community</h3>
              <p className="text-base text-gray-500">
                We believe in fostering a community where people help each other by redistributing gently used items, making every transaction a win-win.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-xl p-8 text-center">
              <LightningBoltIcon className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Effortless Transactions</h3>
              <p className="text-base text-gray-500">
                Our platform is designed to simplify the auction process, offering tools that make listing, bidding, and purchasing items quick and hassle-free.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-xl p-8 text-center">
              <CurrencyDollarIcon className="h-12 w-12 text-blue-600 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Boost Your Savings</h3>
              <p className="text-base text-gray-500">
                Get more value from your items by selling them through our platform, where competitive bidding helps you achieve the best possible price.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue-600 rounded-lg shadow-xl p-8 mb-16 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Get Started with Moveoutauction</h2>
          <p className="text-lg text-white mb-6">
            Ready to start making the most of your move? List your items on our platform and connect with interested buyers today.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
            <Link to="/sell" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50">
              Start Selling
              <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default About;