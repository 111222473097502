import React, { useEffect, useState } from 'react';

const TestPage = () => {
  const [status, setStatus] = useState('Checking authorization...');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const checkAuthorization = async () => {
      const token = localStorage.getItem('jwtToken'); // Ensure key is consistent
      if (!token) {
        setStatus('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch('http://localhost:5000/token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (response.ok) {
          setStatus(`Authorized: ${data.message}`);
          setUserId(`User ID: ${data.user_id}`);
        } else {
          setStatus(`Unauthorized: ${data.error || 'Unknown error'}`);
        }
      } catch (error) {
        setStatus(`Error: ${error.message}`);
      }
    };

    checkAuthorization();
  }, []);

  return (
    <div>
      <h1>Authorization Test Page</h1>
      <div id="status">{status}</div>
      {userId && <div id="user_id">{userId}</div>}
    </div>
  );
};

export default TestPage;
