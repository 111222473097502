// src/components/Layout/Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <Header />
    <main className="flex-grow bg-gradient-to-b from-blue-50 to-white">
      {children}
    </main>
    <Footer />
  </div>
);

export default Layout;