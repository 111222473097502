// src/components/Browse/SortSelect.js
import React from 'react';
import { FilterIcon } from '@heroicons/react/outline';

const SortSelect = ({ sortBy, setSortBy }) => (
  <div className="relative">
    <select
      value={sortBy}
      onChange={(e) => setSortBy(e.target.value)}
      className="w-full p-2 pl-10 border rounded appearance-none"
    >
      <option value="price">Sort by Price</option>
      <option value="name">Sort by Name</option>
    </select>
    <FilterIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
  </div>
);

export default SortSelect;