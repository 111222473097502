// src/components/Layout/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

// Track event function
const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn("Google Analytics not initialized.");
  }
};

const Footer = () => {
  const handleLinkClick = (linkName) => {
    trackEvent('footer_link_click', { link_name: linkName });
  };

  return (
    <footer className="bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div className="px-5 py-2">
            <Link
              to="/about"
              className="text-base text-gray-400 hover:text-white"
              onClick={() => handleLinkClick('About')}
            >
              About
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/contact"
              className="text-base text-gray-400 hover:text-white"
              onClick={() => handleLinkClick('Contact')}
            >
              Contact
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/terms"
              className="text-base text-gray-400 hover:text-white"
              onClick={() => handleLinkClick('Terms and Privacy')}
            >
              Terms and Privacy
            </Link>
          </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2024 Moveoutauction. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
