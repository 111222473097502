import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/outline';
import Layout from '../components/Layout/Layout';

function TermsAndPrivacy() {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="text-center mb-16">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            Terms of Service & Privacy Policy
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            By using Moveoutauction, you agree to these terms and conditions and our privacy practices.
          </p>
        </header>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h2>
          
          <h3 className="text-2xl font-bold text-gray-900 mb-4">1. Introduction</h3>
          <p className="text-base text-gray-500 mb-4">
            Welcome to Moveoutauction, an online platform for buying and selling used items. These Terms of Service ("Terms") govern your access to and use of our website and services. By accessing or using Moveoutauction, you agree to comply with these Terms. If you do not agree, you must not use our platform.
          </p>
          
          <h3 className="text-2xl font-bold text-gray-900 mb-4">2. User Accounts</h3>
          <p className="text-base text-gray-500 mb-4">
            To access certain features of Moveoutauction, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities under your account. Notify us immediately of any unauthorized use or breach of security.
          </p>
          <p className="text-base text-gray-500 mb-4">
            We reserve the right to suspend or terminate accounts that violate these Terms or engage in fraudulent or abusive behavior.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">3. Use of the Platform</h3>
          <p className="text-base text-gray-500 mb-4">
            You may use Moveoutauction to list, buy, and sell items. All content and materials you post must be accurate, complete, and lawful. You must not use the platform to:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Post false or misleading information.</li>
            <li>Engage in activities that may damage, disable, or impair our platform.</li>
            <li>Violate any applicable laws or regulations.</li>
            <li>Transmit or distribute malware or harmful code.</li>
          </ul>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">4. Fees and Payments</h3>
          <p className="text-base text-gray-500 mb-4">
            Moveoutauction may charge fees for certain services, including listing items or processing transactions. Fees will be clearly disclosed before you incur any charges. All payments must be made through our secure payment processing system.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">5. Intellectual Property</h3>
          <p className="text-base text-gray-500 mb-4">
            All intellectual property rights related to Moveoutauction, including trademarks, copyrights, and other proprietary rights, are owned by us or our licensors. You may not use, copy, or distribute any content from our platform without our explicit permission.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">6. Limitation of Liability</h3>
          <p className="text-base text-gray-500 mb-4">
            To the fullest extent permitted by law, Moveoutauction is not liable for any indirect, incidental, special, or consequential damages arising from your use of our platform. Our total liability for any claim related to our services is limited to the amount you paid for those services.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">7. Indemnification</h3>
          <p className="text-base text-gray-500 mb-4">
            You agree to indemnify and hold harmless Moveoutauction and its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses (including reasonable attorneys' fees) arising out of or related to your use of the platform or violation of these Terms.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">8. Termination</h3>
          <p className="text-base text-gray-500 mb-4">
            We may terminate or suspend your account and access to our platform at any time, without notice, if we believe you have violated these Terms or engaged in harmful activities. Upon termination, you must cease all use of the platform.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">9. Governing Law</h3>
          <p className="text-base text-gray-500 mb-4">
            These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any disputes arising from these Terms or your use of the platform will be resolved in the courts located in [Your Jurisdiction].
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">10. Changes to Terms</h3>
          <p className="text-base text-gray-500 mb-4">
            We reserve the right to modify these Terms at any time. Changes will be posted on this page, and the revised Terms will take effect immediately upon posting. Your continued use of Moveoutauction constitutes your acceptance of the updated Terms.
          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h2>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">1. Information We Collect</h3>
          <p className="text-base text-gray-500 mb-4">
            We collect personal information that you provide, including your name, email address, phone number, and payment details. We also collect information about your interactions with our platform, such as your browsing history, search queries, and transaction details.
          </p>
          
          <h3 className="text-2xl font-bold text-gray-900 mb-4">2. How We Use Your Information</h3>
          <p className="text-base text-gray-500 mb-4">
            We use your information to:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Facilitate transactions and provide customer support.</li>
            <li>Improve and personalize your experience on our platform.</li>
            <li>Send you updates, promotional materials, and other communications related to our services.</li>
            <li>Analyze usage patterns to enhance our platform's functionality and performance.</li>
          </ul>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">3. Data Security</h3>
          <p className="text-base text-gray-500 mb-4">
            We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">4. Third-Party Services</h3>
          <p className="text-base text-gray-500 mb-4">
            Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies before providing any personal information.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">5. Cookies and Tracking Technologies</h3>
          <p className="text-base text-gray-500 mb-4">
            We use cookies and similar tracking technologies to collect information about your interactions with our platform. This helps us understand user behavior, provide personalized content, and improve our services. You can manage your cookie preferences through your browser settings.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">6. Your Rights</h3>
          <p className="text-base text-gray-500 mb-4">
            Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your data. To exercise these rights, please contact us using the details provided below.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">7. Data Retention</h3>
          <p className="text-base text-gray-500 mb-4">
            We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, and resolve disputes. When your data is no longer needed, we will securely delete or anonymize it.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">8. Children's Privacy</h3>
          <p className="text-base text-gray-500 mb-4">
            Moveoutauction is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected such information, we will take steps to delete it.
          </p>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">9. Contact Us</h3>
          <p className="text-base text-gray-500 mb-4">
            If you have any questions or concerns about these Terms or our Privacy Policy, please contact us at:
          </p>
          <address className="text-base text-gray-500 mb-4">
            <p>Email: <a href="mailto:support@moveoutauction.com" className="text-blue-500">support@moveoutauction.com</a></p>
            <p>Address: Moveoutauction #02 Diamond District, Bengaluru, Karnataka</p>
          </address>

          <h3 className="text-2xl font-bold text-gray-900 mb-4">10. Changes to Privacy Policy</h3>
          <p className="text-base text-gray-500 mb-4">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with the revised effective date. Your continued use of Moveoutauction after the posting of changes constitutes your acceptance of the updated policy.
          </p>
        </section>

        <section className="bg-blue-600 rounded-lg shadow-xl p-8 mb-16 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Ready to get started?</h2>
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
            <Link to="/sell" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50">
              List your products now
              <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TermsAndPrivacy;
