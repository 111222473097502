// src/components/Browse/SearchBar.js
import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

const SearchBar = ({ searchTerm, setSearchTerm }) => (
  <div className="relative">
    <input
      type="text"
      placeholder="Search listings..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="w-full p-2 pl-10 border rounded"
    />
    <SearchIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
  </div>
);

export default SearchBar;
