import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { XIcon } from '@heroicons/react/outline';

Modal.setAppElement('#root');

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const ReviewPublishModal = ({ isOpen, onRequestClose, onPublish, customUrl, name, description, deadline }) => {
  const [modalName, setModalName] = useState(name || '');
  const [modalDescription, setModalDescription] = useState(description || '');
  const [modalDeadline, setModalDeadline] = useState(deadline || '');
  const [url, setUrl] = useState(customUrl || ''); // Add custom URL state
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setModalName(name || '');
    setModalDescription(description || '');
    setModalDeadline(deadline || '');
    setUrl(customUrl || '');
  }, [name, description, deadline, customUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem('jwtToken'); // Get the token from local storage
      const response = await axios.post(`${API_BASE_URL}/update-user-details`, {
        name: modalName,
        description: modalDescription,
        deadline: modalDeadline,
        custom_url: url, // Use the state for custom_url
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Add this line
      });

      if (response.status === 200) {
        onPublish();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Review and Publish</h2>
          <button
            onClick={onRequestClose}
            className="text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
        <div>
            <label htmlFor="custom_url" className="block text-sm font-medium text-gray-700 mb-1">
              Shareable Public URL
            </label>
            <div className="flex items-center">
              <span className="text-gray-500 mr-2">moveoutauction.com/</span>
              <input
                type="text"
                id="custom_url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={modalName}
              onChange={(e) => setModalName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description - Mention why are you moving?
            </label>
            <textarea
              id="description"
              value={modalDescription}
              onChange={(e) => setModalDescription(e.target.value)}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="deadline" className="block text-sm font-medium text-gray-700 mb-1">
              Deadline
            </label>
            <input
              type="datetime-local"
              id="deadline"
              value={modalDeadline}
              onChange={(e) => setModalDeadline(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Publishing...' : 'Publish'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReviewPublishModal;
