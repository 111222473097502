import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/Layout/Layout';
import SearchBar from '../components/Browse/SearchBar';
import SortSelect from '../components/Browse/SortSelect';
import ListingCard from '../components/Browse/ListingCard';
import { UserContext } from '../context/UserContext';
import ReviewPublishModal from '../components/ReviewAndPublish';
import { toast } from 'react-toastify';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function MyListings() {
  const { user, loading } = useContext(UserContext);
  const [listings, setListings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('price');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({
    name: '',
    description: '',
    deadline: '',
    customUrl: '',
  });

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setIsLoading(true);
        if (!user || !user._id) throw new Error('User not found or missing user ID');

        const url = `${apiBaseUrl.replace(/\/$/, '')}/api/mylistings`;
        const token = localStorage.getItem('jwtToken');

        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data)) {
          setListings(response.data);
        } else {
          throw new Error('Unexpected data structure received from API');
        }
        setIsLoading(false);
      } catch (error) {
        let errorMessage = 'Failed to fetch listings. ';
        if (error.response) {
          errorMessage += `Server responded with ${error.response.status}: ${error.response.data.error || error.response.statusText}`;
        } else if (error.request) {
          errorMessage += 'No response received from server. Check your network connection.';
        } else {
          errorMessage += error.message;
        }
        setError(errorMessage);
        setIsLoading(false);
      }
    };

    const fetchUserDetails = async () => {
      try {
        if (!user || !user._id) return;

        const response = await axios.get(`${apiBaseUrl}/get-user/${user._id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          const userDetails = response.data;
          setModalData({
            name: userDetails.name || '',
            description: userDetails.description || '',
            deadline: userDetails.deadline || '',
            customUrl: userDetails.custom_url || '',
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (!loading && user) {
      fetchListings();
      fetchUserDetails();
    }
  }, [user, loading]);

  const handleBidPlaced = async (listingId, amount, name, phoneNumber) => {
    const newBidAmount = parseFloat(amount);
    if (!listingId || isNaN(newBidAmount)) {
      return false;
    }
    try {
      const response = await axios.post(`${apiBaseUrl}/api/listings/${listingId}/bid`, { 
        amount: newBidAmount,
        name: name,
        phoneNumber: phoneNumber
      });
      setListings(prevListings =>
        prevListings.map(listing =>
          listing._id === listingId
            ? { ...listing, price: newBidAmount }
            : listing
        )
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const filteredListings = listings.filter(listing =>
    listing && listing.name && listing.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedListings = [...filteredListings].sort((a, b) => {
    if (sortBy === 'price') return (a.price || 0) - (b.price || 0);
    if (sortBy === 'name') return (a.name || '').localeCompare(b.name || '');
    return 0;
  });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handlePublish = () => {
    // Add your publish logic here
    console.log("Publishing listings...");
  };

  const handleCopyToClipboard = () => {
    const publicUrl = `https://moveoutauction.com/${modalData.customUrl}`;
    const message = `Hey everyone,

I’m in the process of moving out and I’ve got a bunch of items that I’m selling. If you’re interested or know someone who might be, here’s a link to check out what’s available: 

${publicUrl}.
  
Feel free to share this with anyone who might be interested. Thanks for helping me out!`;
  
    navigator.clipboard.writeText(message)
      .then(() => {
        toast.success('Public URL and message copied to clipboard! Share it with your network to spread the word about my move-out sale.');
      })
      .catch(err => {
        toast.error('Failed to copy the message. Please try again.');
        console.error('Failed to copy:', err);
      });
  };
  
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">My Listings</h1>
          <div className="flex gap-4">
            <button 
              onClick={openModal} 
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Review and Publish
            </button>
            <button
              onClick={handleCopyToClipboard}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Share
            </button>
          </div>
        </div>
        {listings.length > 1 && (
          <div className="mb-6 flex flex-col sm:flex-row gap-4">
            <div className="sm:w-2/3">
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
            <div className="sm:w-1/3">
              <SortSelect sortBy={sortBy} setSortBy={setSortBy} />
            </div>
          </div>
        )}
        {isLoading ? (
          <p className="text-center">Loading listings...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedListings.map((listing) => (
              <ListingCard 
                key={listing._id} 
                listing={listing} 
                onBidPlaced={(amount, name, phoneNumber) => 
                  handleBidPlaced(listing._id, amount, name, phoneNumber)
                }
              />
            ))}
          </div>
        )}
        {!isLoading && !error && sortedListings.length === 0 && (
  <div className="text-center">
    <p>You have no listings.</p>
    <button
      onClick={() => window.location.href = '/sell'}
      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
    >
      Create your listing now
    </button>
  </div>
)}

        <ReviewPublishModal 
          isOpen={modalIsOpen} 
          onRequestClose={closeModal} 
          onPublish={handlePublish} 
          customUrl={modalData.customUrl}
          name={modalData.name}
          description={modalData.description}
          deadline={modalData.deadline}
        />
      </div>
    </Layout>
  );
}

export default MyListings;
