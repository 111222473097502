import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from '../components/Layout/Layout';
import SearchBar from '../components/Browse/SearchBar';
import SortSelect from '../components/Browse/SortSelect';
import ListingCard from '../components/Browse/ListingCard';
import CountdownTimer from '../components/CountdownTimer';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
console.log('API Base URL:', apiBaseUrl);

// Track event function
const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn("Google Analytics not initialized.");
  }
};

function CustomListings() {
  const { customUrl } = useParams();
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('price');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for additional details
  const [deadline, setDeadline] = useState(null);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch listings
        const listingsUrl = `${apiBaseUrl.replace(/\/$/, '')}/api/listings/${customUrl}`;
        console.log('Fetch attempt details:', {
          url: listingsUrl,
          apiBaseUrl,
          customUrl,
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          vendor: navigator.vendor
        });
  
        const response = await axios.get(listingsUrl, {
          timeout: 10000,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 300;
          },
        });

        console.log('Response details:', {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          data: response.data
        });
  
        if (Array.isArray(response.data)) {
          setListings(response.data);
        } else if (response.data && typeof response.data === 'object') {
          setListings([response.data]);
        } else {
          throw new Error('Unexpected data structure received from API');
        }

        // Fetch additional details
        const detailsUrl = `${apiBaseUrl.replace(/\/$/, '')}/api/custom-details/${customUrl}`;
        const detailsResponse = await axios.get(detailsUrl, {
          timeout: 10000,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 300;
          },
        });

        console.log('Details Response details:', {
          status: detailsResponse.status,
          statusText: detailsResponse.statusText,
          headers: detailsResponse.headers,
          data: detailsResponse.data
        });

        const { deadline, description, name } = detailsResponse.data;
        setDeadline(new Date(deadline));
        setDescription(description);
        setName(name);

        setIsLoading(false);

        // Track page view event
        trackEvent('page_view', { page_path: window.location.pathname });

      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          config: error.config,
          response: error.response ? {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers
          } : 'No response',
          request: error.request ? 'Request was made but no response was received' : 'No request'
        });
  
        let errorMessage = 'Failed to fetch listings. ';
        if (error.code === 'ECONNABORTED') {
          errorMessage += 'The request timed out. Please check your internet connection and try again.';
        } else if (error.message === 'Network Error') {
          errorMessage += 'A network error occurred. Please check your internet connection and ensure the API server is accessible.';
        } else {
          errorMessage += `Error: ${error.message}`;
        }
  
        setError(errorMessage);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [customUrl, navigate, apiBaseUrl]);

  const handleBidPlaced = async (listingId, name, amount, phoneNumber) => {
    const newBidAmount = parseFloat(amount);
    console.log('Custom Listings:', { listingId, name, amount: newBidAmount, phoneNumber });
    
    if (!listingId || isNaN(newBidAmount)) {
      console.error('Invalid listing ID or bid amount');
      return false;
    }
    
    try {
      const response = await axios.post(`${apiBaseUrl}/api/listings/${listingId}/bid`, { 
        amount: newBidAmount,
        name: name,
        phoneNumber: phoneNumber
      });
  
      setListings(prevListings =>
        prevListings.map(listing =>
          listing._id === listingId
            ? { ...listing, price: newBidAmount }
            : listing
        )
      );
      
      // Track bid placed event
      trackEvent('bid_placed', {
        listing_id: listingId,
        name: name,
        amount: newBidAmount,
        phone_number: phoneNumber
      });
  
      return true;
    } catch (error) {
      console.error('Error placing bid:', error);
      return false;
    }
  };

  const filteredListings = listings.filter(listing =>
    listing && listing.name && listing.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedListings = [...filteredListings].sort((a, b) => {
    if (sortBy === 'price') return (a.price || 0) - (b.price || 0);
    if (sortBy === 'name') return (a.name || '').localeCompare(b.name || '');
    return 0;
  });

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">
              Move out auction by {name}
            </h1>
            <p className="text-gray-600 mb-4">
              {description}
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            {deadline && <CountdownTimer deadline={deadline} />}
          </div>
        </div>
        {listings.length > 1 && (
          <div className="mb-6 flex flex-col sm:flex-row gap-4">
            <div className="sm:w-2/3">
              <SearchBar 
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
                onSearch={() => trackEvent('search', { term: searchTerm })}
              />
            </div>
            <div className="sm:w-1/3">
              <SortSelect 
                sortBy={sortBy} 
                setSortBy={setSortBy} 
                onSort={() => trackEvent('sort', { sort_by: sortBy })}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <p className="text-center">Loading listings...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedListings.map((listing) => (
              <ListingCard 
                key={listing._id} 
                listing={listing} 
                onBidPlaced={(amount, name, phoneNumber) => 
                  handleBidPlaced(listing._id, amount, name, phoneNumber)
                }
              />
            ))}
          </div>
        )}
        {!isLoading && !error && sortedListings.length === 0 && (
          <p className="text-center">No listings found for this custom URL.</p>
        )}
      </div>
    </Layout>
  );
}  

export default CustomListings;
